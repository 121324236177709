@mixin font-size($px) {
    font-size: #{$px/$font-size-base}rem;
}

@mixin animation($a, $b: .3s, $c: ease-out) {
    transition: $a $b $c;
}

@mixin max($media, $a: max-width) {
    @media screen and ($a: $media) {
        @content;
    }
}

@mixin min($media, $a: min-width) {
  @media screen and ($a: $media) {
      @content;
  }
}


@mixin grid($gutter: $default-gutter, $media-queries: false) {
    padding: $gutter / 2;
    >li {
        padding: $gutter / 2;
    }
    &.inner {
        margin: -$gutter;
    }
    &.inner-side {
        margin-left: -$gutter;
        margin-right: -$gutter;
    }
    @if($media-queries) {
        @each $key,
        $value in $media-queries {
            @media screen and (max-width: $key) {
                padding: $value / 2;
                >li {
                    padding: $value / 2;
                }
                &.inner {
                    margin: -$value;
                }
                &.inner-side {
                    margin-left: -$value;
                    margin-right: -$value;
                }
            }
        }
    }
}


@mixin shadow {
    /* Rectangle 26: */
    box-shadow: 0 40px 20px -30px rgba(214, 214, 214, 0.50);
}


$main-space: 70px;
$main-space-1550: 70px;
$main-space-1370: 60px;
$main-space-1100: 40px;
$main-space-770: 20px;
@mixin main-space($atribute, $offset: 0, $multiply: 1) {
    #{$atribute}: ($main-space + $offset) / $multiply;
    @media screen and (max-width: $w-1550) {
        #{$atribute}: ($main-space-1550 + $offset) / $multiply;
    }
    @media screen and (max-width: $w-1370) {
        #{$atribute}: ($main-space-1370 + $offset) / $multiply;
    }
    @media screen and (max-width: $w-1100) {
        #{$atribute}: ($main-space-1100 + $offset) / $multiply;
    }
    @media screen and (max-width: $w-770) {
        #{$atribute}: ($main-space-770 + $offset) / $multiply;
    }
}