// Colors
$black: #000;
$white: #FFF;
$gray1: #f5f5f5;
$gray2: #d7d7d7;
$gray3: #9b9b9b;
$gray4: #4a4a4a;
$primary-color: #ac8a53;
$primary-hover-color: #896e42;
$secondary-color: #F00;
$border: gray;
$blue: #1DA1F2;
// Fotns
$heading-font: "Gotham-Light";
$body-font: "Gotham-Book";
$heading-thin: "Gotham-Thin";
$font-size-base: 16px;
// Sizes
$page-width: 1180px;
$content-width: 680px;
//Grids
$default-gutter: 20px;
$twobecameone: 600px;
$threebecameone: 700px;
$fourbecamethree: 980px;
$fourbecametwo: 600px;
$fourbecameone: 400px;
//media
$w-1550: 1550px;
$w-1370: 1370px;
$w-1250: 1250px;
$w-1100: 1100px;
$w-1000: 1000px;
$w-700: 700px;
$w-800: 800px;
$w-770: 770px;
$w-650: 650px;
$w-475: 475px;
$w-360: 360px;
// Frame aroud page
$top: 44px;
$top-1550: 30px;
$top-1100: 25px;
@mixin top($atribute, $offset: 0px, $multiply: 1) {
    #{$atribute}: $multiply * ($top + $offset);
    @media screen and (max-width: $w-1550) {
        #{$atribute}: $multiply *($top-1550 + $offset);
    }
    @media screen and (max-width: $w-1100) {
        #{$atribute}: $multiply *($top-1100 + $offset);
    }
}

$side: 49px;
$side-1550: 34px;
$side-1100: 28px;
$side-770: 20px;
@mixin side($atribute, $offset: 0) {
    #{$atribute}: $side + $offset;
    @media screen and (max-width: $w-1550) {
        #{$atribute}: $side-1550 + $offset;
    }
    @media screen and (max-width: $w-1100) {
        #{$atribute}: $side-1100 + $offset;
    }
    @media screen and (max-width: $w-770) {
        #{$atribute}: $side-770 + $offset;
    }
}