@import 'variables';
@import 'mixins';
/* skeleton */

%after-clear {
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

%before-square {
    position: relative;
    content: '';
    display: block;
    padding-top: 100%;
    width: 100%;
    >div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

.table {
    display: table;
    height: 100%;
    width: 100%;
    >div {
        display: table-cell;
    }
}

%page-element {
    margin-left: auto;
    margin-right: auto;
    max-width: $page-width;
    padding-left: $default-gutter;
    padding-right: $default-gutter; //>ul {
    //    margin-left: -$default-gutter;
    //    margin-right: -$default-gutter;
    //}
}

%middle-absolute {
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

/* skeleton */

* {
    margin: 0;
    padding: 0;
    font-family: inherit;
    color: inherit;
    font-size: inherit;
    background-position: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    background-repeat: no-repeat;
    vertical-align: middle;
    background-size: cover;
    outline: none;
}

body,
html {
    width: 100%;
    min-width: 300px;
    background-color: #FFF;
    /* Po vyvoji odkomentovat */
    /*overflow-x: hidden;
  max-width: 100%;*/
}

body {
    font-family: 'Titillium Web', sans-serif;
}

a {
    text-decoration: none;
    border-color: transparent;
    cursor: pointer;
    &:hover,
    :active {
        border: none;
        border-color: transparent;
        cursor: pointer;
    }
    &.link {
        vertical-align: baseline;
        text-decoration: underline;
    }
    &.active {
        text-decoration: underline;
    }
    & *,
    &>* {
        text-decoration: none;
        border: none;
        border-width: 0;
        border-color: transparent;
    }
}

mark,
strong {
    font-family: inherit;
    color: inherit;
    font-size: inherit;
    font-weight: bold;
    background-color: transparent;
}

h1,
h2,
h3,
h4 {
    font-weight: 300;
}

h1 {
    color: #9B9B9B;
    opacity: .12;
    position: absolute;
    top: 50vh;
    left: 1px;
    font-size: 9vw;
    line-height: 0.8em;
    margin: auto;
    height: 1.8em;
    font-weight: 900;
    white-space: nowrap;
    text-transform: uppercase;
    will-change: transform;
    transform: translate(0, -50%)
}

h2 {
    /* ( Agile delivery ): */
    font-weight: 300;
    font-size: 25px;
    letter-spacing: -0.008em;
    line-height: 1.2em;
    padding-bottom: 1em;
    @extend .marks;
    @media screen and (max-width: 1000px) {
        font-size: 22px;
    }
    @media screen and (max-width: 600px) {
        font-size: 19px;
    }
}

h3 {}

h4 {}

p {
    /* Delivery times in da: */
    opacity: 0.7;
    font-weight: 400;
    font-size: 18px;
    color: #1D1F24;
    letter-spacing: 0;
    line-height: 1.55em;
    strong,
    mark,
    a {
        vertical-align: baseline;
    }
    a {
        text-decoration: underline;
    }
    @media screen and (max-width: 1000px) {
        font-size: 16px;
    }
    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
}

span {
    strong,
    mark,
    a,
    span {
        vertical-align: baseline;
    }
}

picture {
    display: block;
}

ul {
    @extend %page-element;
    max-width: none;
    padding: $default-gutter / 2;
    li {
        float: left;
        display: block;
        padding: $default-gutter / 2;
    }
    &.inner {
        margin: -$default-gutter;
    }
    &.inner-side {
        margin-left: -$default-gutter;
        margin-right: -$default-gutter;
    }
    &.zero {
        padding: 0;
        >li {
            padding: 0;
        }
    }
    &.two {
        >li {
            width: 50%;
            &:nth-child(2n+1) {
                clear: left;
            }
            @media screen and (max-width: $twobecameone) {
                width: 100%;
            }
        }
    }
    &.three {
        >li {
            width: 33.333%;
            &:nth-child(3n+1) {
                clear: left;
            }
            @media screen and (max-width: $threebecameone) {
                width: 100%;
            }
        }
    }
    &.four {
        >li {
            width: 25%;
            &:nth-child(4n+1) {
                clear: left;
            }
            @media screen and (max-width: $fourbecamethree) {
                width: 33.333%;
                &:nth-child(4n+1) {
                    clear: none;
                }
                &:nth-child(3n+1) {
                    clear: left;
                }
            }
            @media screen and (max-width: $fourbecametwo) {
                width: 50%;
                &:nth-child(3n+1) {
                    clear: none;
                }
                &:nth-child(2n+1) {
                    clear: left;
                }
            }
            @media screen and (max-width: $fourbecameone) {
                width: 100%;
            }
        }
    }
    &.reverse {
        direction: rtl;
        >li {
            direction: ltr;
            text-align: left;
        }
    }
    &.inline {
        >li {
            display: inline-block;
            float: none;
        }
        &.top>li {
            vertical-align: top;
        }
        &.bottom>li {
            vertical-align: bottom;
        }
    }
    &.inline-layout {
        line-height: 0;
        font-size: 0;
        >li {
            line-height: 1.2;
            font-size: $font-size-base;
            display: inline-block;
            float: none;
        }
    }
    &.list {
        >li {
            display: block;
            float: none;
        }
    }
    @extend %after-clear;
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: #000;
    display: block;
    color: #FFF;
    ul {
        background: #000;
        top: 70px;
        position: absolute;
        left: 0;
        width: 100%;
        visibility: hidden;
        opacity: 0;
    }
    li {}
    a {
        @include animation(all);
        &:hover {}
    }
}

.logo {
    @include top(top);
    @include side(left);
    position: absolute;
    img {
        width: 80px;
        @media screen and (max-width: 1300px){
            width: 70px;
        }
    }
}

header {
    >div {
        @extend %page-element;
    }
}

section {
    width: 100%;
    max-width: 100%;
    display: block;
    position: relative;
    >div {
        @extend %page-element;
    }
    &.atom {
        max-width: 100%;
        overflow: hidden;
        >div {
            max-width: 100%;
        }
    }
}

footer {
    >div {
        @extend %page-element;
    }
    ul {
        li {}
    }
}

/* -- controls -- */

.btn {
    display: inline-block;
    cursor: pointer;
    position: relative;
    @include animation(all);
    .hover &:hover {}
    &.reverse {
        .hover &:hover {}
    }
    &.blue {
        .hover &:hover {}
    }
    @media screen and (max-width: 980px) {}
}

input {
    &[type=text],
    &[type=email],
    &[type=tel],
    &[type=password] {
        -webkit-appearance: none;
        -webkit-border-radius: 0;
    }
    &[type=submit] {
        @extend .btn;
    }
    &[type=file] {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.000001;
    }
}

.select {
    border: 1px solid #FF0;
    position: relative;
    display: block;
    overflow: hidden;
    &:before {
        content: '';
        width: 0;
        height: 4px;
        border-style: solid;
        border-width: 9px 4px 0 4px;
        border-color: #333 transparent transparent transparent;
        position: absolute;
        right: 10px;
        margin: auto;
        top: 7px;
        bottom: 0;
    }
    select {
        background: none;
        border: none;
        width: calc(100% + 15px);
        border-radius: 0;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        outline: none;
    }
}

button {
    background: none;
    border: 0;
    color: inherit;
    /* cursor: default; */
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-appearance: button;
    /* for input */
    -webkit-user-select: none;
    /* for button */
    -moz-user-select: none;
    -ms-user-select: none;
    @extend .btn;
}

label {
    display: block;
}

.textbox {
    label {}
    span {
        display: block;
        color: #F00;
    }
    input {
        width: 100%;
        display: block;
    }
}

.checkbox {
    label {}
    span {
        display: block;
    }
    input {}
}

.selectbox {
    label {}
    select {
        display: block;
    }
    span {}
}

#lean_overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    display: none;
}

.modal {
    @extend %page-element;
    @extend %middle-absolute;
    max-height: 600px;
    height: 99px;
}

sup {
    font-size: 70%;
    line-height: 80%;
    vertical-align: top;
}

.slider {
    height: 200px;
    position: relative;
    width: 300px;
    margin: auto;
    background: #DDD;
    >div {
        height: 100%;
        width: 300px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: greenyellow;
        will-change: transform;
        &:nth-child(2n) {
            background: fuchsia;
        }
        &:nth-child(7n) {
            background: lightseagreen;
        }
    }
}

.flexbox-wrapper {
    display: flex;
    align-content: stretch;
    flex-flow: row wrap;
    align-items: center;
    &.top {
        align-items: flex-start;
    }
    &.bottom {
        align-items: bottom;
    }
    &.stretch {
        align-items: stretch;
    }
    >div,
    >a {
        flex: 1;
    }
}

@import 'font-awesome';
.point {
    width: 93px;
    height: 93px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    div {
        height: 100%;
        width: 100%;
        padding: 13px;
        border-radius: 50%;
        /* Oval 5: */
        //opacity: 0.6;
        background: rgba(255, 255, 255, 0.60);
        box-shadow: 0 0 34px 0 rgba(200, 200, 200, 0.55);
        position: relative;
    }
    h2 {
        letter-spacing: 0.10em;
        text-align: center;
        position: absolute;
        white-space: nowrap;
        font-weight: 300;
        width: 0;
        &:before,
        &::after {
            display: none;
        }
        span {
            display: block;
            font-weight: 400;
            font-size: 14px;
            position: absolute;
            transform: translate(0, -50%);
            top: 0;
            @extend .marks;
            @include animation(letter-spacing);
            @media screen and (min-width: 1200px) {
                font-size: 16px;
            }
            @media screen and (min-width: 1450px) {
                font-size: 18px;
            }
        }
    }
    >div {
        div {
            @include animation(box-shadow);
            will-change: box-shadow;
            background: rgba(255, 255, 255, 0.80);
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                @include animation(box-shadow);
                background: #FFFFFF;
                border-radius: 50%;
                box-shadow: inset 0 0 0px 0 rgba(222, 222, 222, 0.20);
            }
        }
        .hover &:hover {
            div {
                box-shadow: 0 0 6px 0 rgba(222, 222, 222, 0.20);
                &:before {
                    box-shadow: inset 0 0 10px 0 rgba(222, 222, 222, 0.20);
                }
            }
            h2 span {
                font-weight: 700;
                letter-spacing: 0.428em;
                @media screen and (min-width: 1200px) {
                    letter-spacing: 0.35em;
                }
                @media screen and (min-width: 1450px) {
                    letter-spacing: 0.3em;
                }
            }
        }
    }
    .dot {
        padding: 0;
        box-shadow: none;
        border-radius: 50%;
        background: #1DA1F2;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 12px;
        height: 12px;
        opacity: 0.8;
        &:before,
        &::after {
            display: none;
        }
    }
    &--central {
        width: 193px;
        height: 193px;
        right: 0;
        bottom: 0;
        margin: auto;
        opacity: 1;
        div {
            padding: 30px;
        }
    }
    @media screen and (max-width: 1200px) {
        width: 83px;
        height: 83px;
        div {
            padding: 11px;
            box-shadow: 0 0 15px 0 rgba(222, 222, 222, 0.570);
        }
        &--central {
            width: 115px;
            height: 115px;
            right: 0;
            bottom: 0;
            margin: auto;
            div {
                padding: 15px;
            }
        }
    }
    @media screen and (max-width: 1000px) {
        width: 83px;
        height: 83px;
        div {
            padding: 11px;
            box-shadow: 0 0 15px 0 rgba(222, 222, 222, 0.570);
            &--central {
                width: 193px;
                height: 193px;
                right: 0;
                bottom: 0;
                margin: auto;
                div {
                    padding: 30px;
                }
            }
        }
    }
    @media screen and (max-width: 730px) {
        width: 53px;
        height: 53px;
        div {
            padding: 9px;
        }
    }
}

.point-simple__H2 {
    &--1 {
        transform: translate(0, -60px);
        span {
            right: 0;
        }
    }
    &--2 {
        transform: translate(40px, -60px);
    }
    &--3 {
        transform: translate(44px, -60px);
        span {
            right: 0;
        }
    }
    &--4 {
        transform: translate(-40px, 60px);
        span {
            right: 0;
        }
    }
    &--5 {
        transform: translate(30px, 70px);
    }
    @media screen and (max-width: 1000px) {
        &--1 {
            transform: translate(0, -54px);
        }
        &--2 {
            transform: translate(-40px, -54px);
        }
        &--3 {
            transform: translate(44px, -54px);
        }
        &--4 {
            transform: translate(-40px, 54px);
        }
        &--5 {
            transform: translate(-30px, 65px);
        }
    }
    @media screen and (max-width: 730px) {
        &--1 {
            transform: translate(0, -35px);
        }
        &--2 {
            transform: translate(-60px, -35px);
        }
        &--3 {
            transform: translate(-56px, -37px);
            span {
                right: auto;
                left: 0;
            }
        }
        &--4 {
            transform: translate(0, 49px);
        }
        &--5 {
            transform: translate(0, 49px);
        }
    }
}

#atom-control {
    height: 100vh;
    width: 100%;
    position: relative;
    padding-top: 10px;
    padding-bottom: 0px;
    svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        path {
            stroke: #979797;
            opacity: 0;
            &.signal{
                opacity: 1;
                stroke: $blue;
            }
        }
    }
    .atom-control--wrapper {
        position: relative;
        height: 100%;
        width: 100%;
    }
    @media screen and (max-width: 1000px) {
        padding-top: 52px;
        padding-bottom: 20px;
    }
}

.marks {
    &:before {
        content: '(';
        display: inline-block;
        vertical-align: baseline;
        color: $blue;
    }
    &:after {
        content: ')';
        display: inline-block;
        vertical-align: baseline;
        color: $blue;
    }
}

.distribution {
    >div,
    >a {
        text-align: center;
        &:first-child {
            text-align: right;
        }
        &:last-child {
            text-align: left;
        }
    }
}

.reasons {
    padding-top: 110px;
    padding-bottom: 70px;
    background: #FFF;
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        color: $blue;
        width: calc(100% - 40px);
        margin: auto;
        height: 1px;
        background: #e0e0e0;
        max-width: 1280px;
        top: 0;
        left: 0;
        right: 0;
    }
    h2 {
        padding-top: 20px;
    }
    &__layout {
        li {
            text-align: center;
            &:first-child {
                text-align: left;
            }
            &:last-child {
                text-align: right;
            }
        }
        @media screen and (max-width: $threebecameone) {
            max-width: 340px;
            margin: auto;
            li {
                text-align: left;
                &:last-child {
                    text-align: left;
                }
            }
        }
    }
    &__content {
        max-width: 310px;
        display: inline-block;
        text-align: left;
    }
    @media screen and (max-width: 720px) {
        padding-top: 50px;
    }
}

.contactus {
    padding-top: 120px;
    padding-bottom: 120px;
    position: relative;
    background: #f6f6f6;
    &__text {
        /* do you need us? >>>: */
        font-weight: 600;
        font-size: 14px;
        color: #1D1F24;
        letter-spacing: 0.357em;
        line-height: 1.4;
        text-transform: uppercase;
        &__at {
            //font-size: 70px;
            //color: $blue;
            display: inline-block;
            padding-left: 24px;
            padding-right: 24px;
            svg {
                width: 43px;
                display: block
            }
        }
        @media screen and (max-width: 1000px) {
            font-size: 11px;
            &__at {
                svg {
                    width: 32px;
                    display: block
                }
            }
        }
        @media screen and (max-width: 720px) {
            display: block;
            >div,
            a {
                display: block;
                text-align: center;
                &:first-child,
                &:last-child {
                    text-align: center;
                }
            }
        }
    }
    &__text__link {
        .left-arr,
        .right-arr {
            display: inline-block;
            span{
                @include animation(opacity);
                @include animation(opacity);
                vertical-align: baseline;
                padding: 0;
                margin: 0;
            }
        }
        .hover &:hover {
            .left-arr {
                transform: translate(10px, 0)
            }
            .right-arr {
                transform: translate(-10px, 0)
            }
        }
    }
    &__place {
        position: absolute; //@include top(bottom);
        bottom: 20px;
        @include side(left);
        /* BASED IN PRAGUE, CZ: */
        font-weight: 600;
        font-size: 12px;
        color: #9B9B9B;
        letter-spacing: 2.57px;
        text-align: center;
        line-height: 1.2;
        mark {
            color: #000;
        }
        @media screen and (max-width: 1450px) {
            font-size: 10px;
        }
        @media screen and (max-width: 1000px) {
            font-size: 9px;
        }
        @media screen and (max-width: 600px) {
            font-size: 8px;
        }
    }
    @media screen and (max-width: 720px) {
        padding-top: 70px;
    }
}

.scroll-anchor {
    position: fixed;
    right: 30px;
    font-size: 12px;
    z-index: 100;
    span {
        position: absolute;
        transform: rotate(-90deg) translate(17px, 0);
        transform-origin: left;
        height: 1em;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 50%;
        line-height: .7em;
        white-space: nowrap;
    }
    @media screen and (max-width: 600px){
        right: 17px;
    }
}

#scroll-down {
    bottom: 30px;
    @media screen and (max-width: 600px){
        bottom: 17px;
    }
    svg {
        transform: rotate(180deg);
    }
    span {

    }
}

#scroll-top {
    top: 30px;
    transform: translate(0, -30%);
    opacity: 0;
    @media screen and (max-width: 600px){
        top: 17px;
    }
    span {
        transform: rotate(-90deg) translate(-20px, 0);
        right: 50%;
        left: auto;
        transform-origin: right;
    }
}